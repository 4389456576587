import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const CVPage = () => (
  <Layout>
    <Seo title="CV" />
    <h1 className="text-center print:pt-3">Dardan Haxhimustafa</h1>
    <div className="bg-dardan-green print-cv-green text-white box-border px-20 py-5 flex row flex-row-reverse place-items-center gap-5 print:mt-8">
      <StaticImage
        src="../images/cv_profile.jpeg"
        formats={["auto", "webp", "avif"]}
        alt="Dardan's Profile Picture"
        className="w-200 h-200 object-cover rounded-full border-solid border-4 border-white"
      />
      <div className="grow">
        {
          [
            {
              label: 'Birthday',
              value: '20-12-1992'
            },
            {
              label: 'Address',
              // value: <>
              //   Berggasse 37/3/47<br/>
              //   1090 Wien, Austria
              // </>,
              value: '(undisclosed)',
            },
            {
              label: 'Citizenship',
              value: 'Austrian'
            },
            {
              label: 'Email',
              // value: 'mail@dardan.im',
              value: '(undisclosed)',
            },
            {
              label: 'Phone',
              // value: '+43 699 173 55 403',
              value: '(undisclosed)'
            }
          ].map(item => (
            <div className="flex row gap-1">
              <div className="basis-3/12">{item.label}:</div>
              <div className="basis-9/12 font-medium">{item.value}</div>
            </div>
          ))
        }
      </div>
    </div>
    <div className="flex row print:w-11/12 print:mx-auto print:gap-3">
      <div className="flex-grow basis-7/12">
        <h3 className='border-b border-b-dardan-green mr-4 print:pt-3'>Education:</h3>
        <ul>
          <li>2010 - 2013: <strong>Association Loyola Gymnasium</strong></li>
          <li>2013s - 2019w: <strong>TU Wien, BSc. Software Engineering</strong></li>
          <li>2020s - idle: <strong>TU Wien, MSc. Software Engineering</strong></li>
        </ul>
        <h3 className='border-b border-b-dardan-green mr-4 print:pt-3'>Work experience:</h3>
        <ul>
          <li>2018 - 2022: <strong>Software Engineer</strong> at <strong>Netconomy</strong></li>
          <li>2022 - 2022: <strong>Software Engineer</strong> at <strong>cargo-partner</strong></li>
          <li>2022 - current: <strong>Software (& search) Engineer</strong> at <strong>Swisscom Directories AG</strong></li>
        </ul>
      </div>
      <div className="flex-grow basis-5/12">
        <h3 className='border-b border-b-dardan-green mr-4 print:pt-3'>Languages</h3>
        <ul>
          <li><strong>Albanian</strong>: native</li>
          <li><strong>English</strong>: fluent</li>
          <li><strong>German</strong>: B2/2</li>
        </ul>
        <h3 className='border-b border-b-dardan-green mr-4 print:pt-3'>Soft Skills:</h3>
        <ul className="list-disc pl-6 box-border">
          <li>Team working</li>
          <li>Easy to communicate to</li>
          <li>Open and eager to learn new things</li>
        </ul>
      </div>
    </div>
    <div className='print:w-11/12 print:mx-auto'>
      <h3 className='border-b border-b-dardan-green mr-4 print:pt-3'>Experience with technologies</h3>
      <h4>Java Web Development {years('+9')}</h4>
      <p>
        With <strong>Spring, Hibernate, JPA, JavaEE, Maven</strong>, etc.. 
      </p>
      <h4>Go</h4>
      <p>
        Writing and maintaining microservices. Paired with the gin web framework providing both openapi and graphql apis.
      </p>
      <h4>Databases {years('+10')}</h4>
      <p>
        Frequently used: <strong>PostgreSQL</strong> (including Pg/PlSQL), <strong>MongoDB</strong>, <strong>MySQL</strong>.<br/>
        Text-search and Logging: <strong>Solr</strong>, <strong>ElasticSearch</strong>.<br/>
        Familiar with: <strong>Sqlite, ArangoDB, CouchDB</strong> etc.. 
      </p>
      <h4>Rust {years('4')}</h4>
      <p>
        Private group project: Matrix (federated chat) server with <strong>Actix</strong> and <strong>PostgreSQL</strong>.<br/>
        I have also used it in multiple private personal projects from then.<br/>
        Experience with <strong>actix, diesel, sqlx, serde, procedural macros, etc..</strong>
      </p>
      <h4>C and C++</h4>
      <p>
        Project: (kde's) Kconfig parser for <strong>libelektra</strong>.<br/>
        Intermediate knowledge of: GUI(<strong>QT</strong>), microcontrollers and parallelisation libraries like <strong>OpenMP, OpenMPI</strong>. 
      </p>
      <h4>Javascript {years('9')}</h4>
      <p>
        Experience with <strong>React, JQuery, Angular</strong> and static site generators: <strong>Gatsby and Eleventy</strong>.<br/>
        In the last 4 years I have mostly used <strong>typescript</strong>.
      </p>
      <h4>Devops</h4>
      <p>
        Depending on projects I have experience with deploying directly to virtual servers and manually handling nginx configs as well as by using fully declarative IaaS.<br/>
        Used <strong>Kubernetes</strong>, <strong>Kustomize</strong>, <strong>Terraform</strong>, <strong>Docker</strong> with platforms such as GCP and Azure.
      </p>
      <h4>Other</h4>
      <p>
        I started learning <strong>PHP</strong> when I was just a kid and never stopped learning new languages.
        I have used <strong>Haskell, Elm, Python, Bash</strong>.. until now and plenty of other technologies alongside them. 
      </p>
    </div>
    <div className="p-4"></div>
  </Layout>
)

const years = (y) => <><span className="font-thin text-base">({y} years)</span></>;

export default CVPage
